body {
  margin: 0;


    font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(12, 12, 12);
}
html {
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Header Container */
.header-container {
  /* background-color: rgb(26, 25, 25); */
  color: #fff;
  position: relative;
  text-align: center;
  width: auto !important;

}







/* ask charles if its ok*/
::-webkit-scrollbar {
  display: none;
}

















.header-split{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Navigation Styles */
.nav {
  margin: 0;
  margin-left: auto;

  
}


.nav-list {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.link-dom{
  text-decoration: none;
  color: white;
}

.nav-item {
  display: inline;
  align-self: center;
}
.nav-link {
  text-decoration: none;
  color: white;
  font-size: 18px;
  
  margin: 0 30px;
  transition: border-bottom 0.2s ease-in; /* Adjust the duration and timing function as needed */
  border-bottom: 2px solid transparent; /* Add a transparent border by default */
}


.nav-link:hover {
  border-bottom: 2px solid #fff;
}


.image-projects{
  width: 300px;
  height: 400px;
}

#tsparticles {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}



.header-split{
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.logo{
  margin-left: 30px;
  color: white;
  font-size: 18px;
}

.banner{
  width: 83%;
  height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  /* border-top: 5px solid yellow;

  border-bottom: 5px solid yellow; */
}
.homebg{
  overflow: hidden !important;

 position: absolute;
 z-index: -1;
 width: 100%;

 height: 100%;
}


.home-container{
  /* background-color: rgb(12, 12, 12); */
  width: 100%;
  height: 100vh;
  position: relative;
  /* background-image: linear-gradient(rgba(70, 69, 69, 0.5), rgba(2, 2, 2, 0.726)), url("./img/home-bg1.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important; */
}



.home-header{
  font-size: 30px;
  color: white;
}

.home-header-container{
  height: 85%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.text-center{
  text-align: center !important;
}

.project-btn {
  font-size: 20px;
  color: white;
  background: rgb(14, 14, 14);
  border: 1px solid #ffc908; /* Fixed border property */
  margin-left: auto;
  margin-right: auto;
  width: 110px;
  padding: 2px 5px;
  border-radius: 10px;
  transition: 0.5s; /* Shorter transition duration */

/* Removed -webkit- prefix */
}

.project-btn:hover {
  animation: none; /* Removed -webkit- prefix */
  background: #ffc908;
  box-shadow: 0 0 15px #ffc908;
  border: 1px solid #ffc908; /* Added solid style */
  color: white !important;
  cursor: pointer;
}
.bg {
  animation: pulse 1.2s ease infinite;
  background: #ffc908;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}














.home-content{
  width: 100%;
  height: 100%;
  position: relative;
}

.text{
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


.canvas {
  overflow: hidden !important;

  position: absolute;
  z-index: -1;
  width: 100%;

  height: 100vh;

}
.w-full{
  position: absolute;

  z-index: -3;
  height: 100%;
  width: 100%;
}
.w-full2 {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -2; /* Ensure the container is above other elements */
}
#tsparticles{
  display: block;
 }

 #tsparticles2{
  display: block;
  height: 100% !important;
 }

 .w-full-bg{
  position: relative;
 }


/* Responsive styles for smaller screens */
/* @media screen and (max-width: 650px) {
  .centered-divs {
    margin-top: 20px;
    flex-direction: column; 
    text-align: center; 
    
  }

  .home-content{
    height: 100%;
  }

  
.home-divs{

  width: 200px !important;
  height: 300px !important;
  margin-bottom: 30px;
}
} */


.insta-svg{
  height: 65px;
  cursor: pointer;
}

.centered-divs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; /* Adjust alignment as needed */
  min-height: 100vh; /* Use min-height to ensure flexibility */
}

.home-divs {
  border: 1px white solid;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  width: 400px !important; /* Adjust this value */
  max-width: 80%;
  margin: 10px;
  height: 250px;
}




.insta-div {
  border: 1px white solid;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: space-around;
  align-items: center;
  border-radius: 16px;
  width: 600px !important; /* Adjust this value */
  max-width: 80%;
  margin: 10px;
  height: 100px;
}








/* Projects.css */

.project-boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}

.project-box {
  width: 350px;
  padding: 20px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.project-box img {
  max-width: 100%;
  max-height: 200px;
}

.project-box h2 {
  font-size: 1.5rem;
  color: white;
}

.project-box p {
  font-size: 1rem;
  color: white;
}



.pay-btn{
  background: rgb(14, 14, 14);
  border: white 1px solid;
  font-size: 15px;
  color: white;
  padding: 6px 20px;
  border-radius: 12px;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transition: 0.5s;
}


.pay-btn:hover {
  background: #ffc908;
  box-shadow: 0 0 15px #ffc908;
  border:1px #ffc908 solid;
  color: white !important;
  cursor: pointer;
}

.text-center-white{
  text-decoration: none;
  color: white;
}

/* Assuming you want to change the color of the thumb (draggable part of the scrollbar) */
.custom-scrollbar .simplebar-scrollbar::before {
  background-color: #424246; /* Replace with your desired color code */
}

.project-title{
  font-size: 24px !important;
}

.paypal-options{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.res-text{
  color: white !important;
}

.content{
  height: 100% !important;
}


@media screen and (max-width: 650px) {
  .label-text{
    flex-direction: column;
    margin-bottom: 5px !important;
    width: 200px !important;
  }

  .label-text-mobile{
    align-self: flex-start !important;
  }
}

.label-text-mobile{
  align-self: center;
}

.form-pp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}





.label-text{
  color: white;
  display: flex;
  margin-bottom: 40px;
  width: 400px;
  font-size: 20px;
  align-items: center;
  justify-content: space-between;
}

.form-container{
  margin-left: auto;
  margin-right: auto;
  width: 350px;
}
.text-field {
  /* Common styles for the text field */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.dark-theme {
  /* Dark theme styles */
  background-color: #212224;
  color: #fff;
  border-color: #47484b;
}

/* You can add more styles based on your design preferences */

.submit-btn-pp{
  width: 140px;
  height: 30px;
  background: none;
  cursor: pointer;
  border: 1px solid #ffc908;
  border-radius: 12px;
  color: white;
  transition: .5s;
  margin-top: 30px;

}

.submit-btn-pp:hover{
  color: black;
  background: #ffc908;
  box-shadow: 0 0 15px #ffc908;
}

.submit-btn-pp-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.user-info{
  width: 600px;
  color: white;
  display: flex;
  justify-content: space-around;
}


.footer{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.header-img{
  width: 45px;
  height: 45px;
}
