:root {
    --clr-neutral-900: hsl(207, 19%, 9%);
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: #ffc908;
  }

.card {
    color: var(--clr-neutral-100);
    background-image: url(https://media.contentapi.ea.com/content/dam/eacom/images/2020/09/ea-featured-image-ea-desktop-beta.jpg.adapt.crop191x100.1200w.jpg);
    background-size: cover;
    min-width: 275px !important;
    margin-top: 10px;
    width:100%;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms ease;
  }
  
  .card:hover,
  .card:focus-within {
    transform: scale(1.05);
  }
  
  .card-content {
    --padding: 1.3rem;
    padding: var(--padding);
    padding-bottom: 60px;
  
    background: linear-gradient(
      hsl(0 0% 0% / 0),
      hsl(20 0% 0% / 0.3) 20%,
      hsl(0 0% 0% / 1)
    );
  }
  
  .card-title {
    position: relative;
    width: max-content;
    font-size: 30px;
    color: white;
  }
  
  .card:hover .card-title::after,
  .card:focus-within .card-title::after {
    transform: scaleX(1);
  }
  
  .card-title::after {
    content: "";
    position: absolute;
    height: 4px;
    width: calc(100% + var(--padding));
    left: calc(var(--padding) * -1);
    bottom: -2px;
    background: var(--clr-accent-400);
    transform-origin: left;
    transition: transform 500ms ease;
  }
  
  @media (hover) {
    .card-content {
      transform: translateY(60%);
      transition: transform 500ms ease;
    }
  
    .card-content > *:not(.card-title) {
      opacity: 0;
      transition: opacity 500ms linear;
    }
  
    .card:hover .card-content > *:not(.card-title),
    .card:focus-within .card-content > *:not(.card-title) {
      opacity: 1;
      transition-delay: 700ms;
    }
  
    .card:hover .card-content,
    .card:focus-within .card-content {
      transform: translateY(0);
      transition-delay: 500ms;
    }
  
    .card:focus-within .card-content {
      transition-duration: 0ms;
    }
  
    .card-title::after {
      transform: scaleX(0);
    }
  }
  
  .button {
    text-decoration: none;
  }
  
  .button:hover,
  .button:focus {
    background-color: var(--clr-neutral-100);
  }
  
  .card-body {
    color: rgb(255, 255, 255/0.85);
    margin-top: 10px;
    margin-bottom: 50px;
  }
  
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
      transition-delay: 0ms !important;
    }
  }

  .home-link{
    cursor: pointer;
    border: none;
    text-decoration: none;
    min-width: 35%;
  }
  